<template>
  <div class="ClientDataDebtorsTable column">
    <label class="fs-18 fw-med mt-22 mb-22">Top 10 Debtors</label>

    <div class="ClientDataDebtorsTable__table column">
      <div class="ClientDataDebtorsTable__head-wrapper row row--justify-between">
        <label class="ClientDataDebtorsTable__head-lbl">Debtor</label>
        <label class="ClientDataDebtorsTable__head-lbl">Current Volume</label>
        <label class="ClientDataDebtorsTable__head-lbl">Total Volume</label>
        <label class="ClientDataDebtorsTable__head-lbl">Percent of Current</label>
        <label class="ClientDataDebtorsTable__head-lbl">Avg Days to Pay</label>
      </div>

      <!-- DEBTORS -->
      <div
        v-for="(debtor, index) in debtors"
        :key="index"
        class="ClientDataDebtorsTable__debtor row row--align-center row--justify-between"
      >
        <label class="ClientDataDebtorsTable__debtor-column fs-15">
          {{ debtor.debtor.name }}
        </label>
        <label class="ClientDataDebtorsTable__debtor-column fc-light fs-15">
          {{
            Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(debtor.volume.current)
          }}
        </label>
        <label class="ClientDataDebtorsTable__debtor-column fc-light fs-15">
          {{
            Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(debtor.volume.total)
          }}
        </label>
        <label class="ClientDataDebtorsTable__debtor-column fc-light fs-15">{{ debtor.percentCurrent }}%</label>
        <label
          :class="['ClientDataDebtorsTable__debtor-column fc-light fs-15', {
            'fc-red': debtor.debtor.external_days_to_pay_total > 45
          }]"
        >
          {{ debtor.debtor.external_days_to_pay_total }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientDataDebtorsTable',

  props: {
    debtors: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="sass">
.ClientDataDebtorsTable
  $block: &
  background-color: $white
  width: 100%

  &__category-heading
    margin: rem(22px) 0

  &__category-value-pre-lbl
    margin-right: rem(9px)

  &__debtor
    border-top: $border
    padding: rem(17px) rem(27px)

  &__debtor-column

    &:nth-child(1) // Debtor
      flex: 0 0 rem(250px)
      margin-right: 2rem
    &:nth-child(2) // Current volume
      flex: 1 0 0
      margin-right: 1rem
    &:nth-child(3) // Total volume
      flex: 1 0 0
      margin-right: 1rem
    &:nth-child(4) // Percent current
      flex: 1 0 0
      margin-right: 1rem
    &:last-child // Avg days to pay
      flex: 0 0 rem(100px)

  &__head-lbl
    +font-medium
    color: #9EA0A5
    font-size: rem(12px)
    line-height: rem(18px)
    text-align: left
    text-transform: uppercase
    white-space: nowrap

    // All widths have 16px added for the margin-right of the approved-item's top-container .column
    // Except the last as it does not have margin-right
    &:nth-child(1) // Debtor
      flex: 0 0 rem(250px)
      margin-right: 2rem
    &:nth-child(2) // Current volume
      flex: 1 0 0
      margin-right: 1rem
    &:nth-child(3) // Total volume
      flex: 1 0 0
      margin-right: 1rem
    &:nth-child(4) // Percent current
      flex: 1 0 0
      margin-right: 1rem
    &:last-child // Avg days to pay
      flex: 0 0 rem(100px)

  &__head-wrapper
    +flex()
    border-radius: rem(4px) rem(4px) 0 0
    padding: rem(10px) rem(27px)

  &__table
    background-color: $white
    box-shadow: $container-shadow
    width: 100%
</style>