var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ClientDataDebtorsTable column" }, [
    _c("label", { staticClass: "fs-18 fw-med mt-22 mb-22" }, [
      _vm._v("Top 10 Debtors")
    ]),
    _c(
      "div",
      { staticClass: "ClientDataDebtorsTable__table column" },
      [
        _vm._m(0),
        _vm._l(_vm.debtors, function(debtor, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "ClientDataDebtorsTable__debtor row row--align-center row--justify-between"
            },
            [
              _c(
                "label",
                { staticClass: "ClientDataDebtorsTable__debtor-column fs-15" },
                [_vm._v("\n        " + _vm._s(debtor.debtor.name) + "\n      ")]
              ),
              _c(
                "label",
                {
                  staticClass:
                    "ClientDataDebtorsTable__debtor-column fc-light fs-15"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(debtor.volume.current)
                      ) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "label",
                {
                  staticClass:
                    "ClientDataDebtorsTable__debtor-column fc-light fs-15"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(debtor.volume.total)
                      ) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "label",
                {
                  staticClass:
                    "ClientDataDebtorsTable__debtor-column fc-light fs-15"
                },
                [_vm._v(_vm._s(debtor.percentCurrent) + "%")]
              ),
              _c(
                "label",
                {
                  class: [
                    "ClientDataDebtorsTable__debtor-column fc-light fs-15",
                    {
                      "fc-red": debtor.debtor.external_days_to_pay_total > 45
                    }
                  ]
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(debtor.debtor.external_days_to_pay_total) +
                      "\n      "
                  )
                ]
              )
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "ClientDataDebtorsTable__head-wrapper row row--justify-between"
      },
      [
        _c("label", { staticClass: "ClientDataDebtorsTable__head-lbl" }, [
          _vm._v("Debtor")
        ]),
        _c("label", { staticClass: "ClientDataDebtorsTable__head-lbl" }, [
          _vm._v("Current Volume")
        ]),
        _c("label", { staticClass: "ClientDataDebtorsTable__head-lbl" }, [
          _vm._v("Total Volume")
        ]),
        _c("label", { staticClass: "ClientDataDebtorsTable__head-lbl" }, [
          _vm._v("Percent of Current")
        ]),
        _c("label", { staticClass: "ClientDataDebtorsTable__head-lbl" }, [
          _vm._v("Avg Days to Pay")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }