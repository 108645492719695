<template>
  <div class="ClientDataDebtors">
    <transition name="Transition__fade">
      <client-data-debtors-table
        v-if="requestsFinished"
        :debtors="top10Debtors"
      />
    </transition>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  Debtor,
  Invoice
} from '../../../../utils/api'
import {
  transformRawInvoice
} from '../../../../utils/helpers'
// Components
import ClientDataDebtorsTable from './client-data-debtors-table.vue'

export default {
  name: 'ClientDataDebtors',

  components: {
    ClientDataDebtorsTable,
  },

  async created () {
    console.log('CLIENT DATA DEBTORS')
    this.progressStart()
    await this.getApprovedInvoices()
    this.progressFinish()
  },

  data () {
    return {
      requestsFinished: false,
      top10Debtors: null,
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    calculateCurrentVolume (debtors) {
      const debtorsWithCurrentVolume = debtors

      debtorsWithCurrentVolume.forEach(debtor => {
        // First, total each debtor's current volume
        debtor.volume.current = debtor.approvedInvoices
          .reduce((acc, cur) => acc + cur.amounts.total, 0)
        // We no longer need the approved invoices, so we'll remove
        delete debtor.approvedInvoices
      })

      return debtorsWithCurrentVolume
    },

    calculatePercentCurrent (debtors) {
      const debtorsWithPercentCurrent = debtors

      debtorsWithPercentCurrent.forEach(debtor => {
        debtor.percentCurrent = (debtor.volume.current / debtor.volume.total * 100).toFixed(2)
      })

      return debtorsWithPercentCurrent
    },

    async calculateTotalVolume (debtors) {
      const debtorsWithTotalVolume = debtors

      debtorsWithTotalVolume.forEach(async debtor => {
        // Second, total each debtor's total volume
        // Total = current volume + all paid invoices between debtor and client
        debtor.volume.total = (await this.getPaidInvoices(debtor.debtor.id))
          .reduce((acc, cur) => acc + cur.amounts.total, 0)
        // Third, calculate the percent of total that is current
        debtor.percentCurrent = (debtor.volume.current / debtor.volume.total * 100).toFixed(2)
      })

      return debtorsWithTotalVolume
    },

    async categorizeApprovedInvoicesByDebtor (transformedInvoices) {
      // organizing by debtor.id
      const debtors = []

      transformedInvoices.forEach(invoice => {
        const invoiceDebtorID = invoice.debtor.id

        // Must add each invoice debtor to debtors on first iteration
        if (!debtors.filter(debtor => debtor.debtor.id === invoiceDebtorID).length) {
          debtors.push({
            approvedInvoices: [invoice],
            debtor: invoice.debtor,
            percentCurrent: null,
            volume: {
              current: 0,
              total: 0,
            },
          })
          return
        }

        // Add to an existing categoriy using debtor.id
        debtors.filter(result => result.debtor.id === invoiceDebtorID)[0].approvedInvoices.push(invoice)
      })

      await this.constructTop10(debtors)
    },

    async constructTop10 (debtors) {
      // First, calculate and update debtors with their current volume
      const debtorsWithCurrentVolume = await this.calculateCurrentVolume(debtors)
      // Second, find the total volume of only the top 10 (based on current volume)
      const debtorsWithTotalVolume = await this.calculateTotalVolume(
        debtorsWithCurrentVolume
          .sort((a, b) => {
            // Sort by desc total of invoices in each debtor's invoices
            return b.volume.current - a.volume.current
          })
          .slice(0, 10)
      )
      // Third, calculate the percent total for debtors who have their volume calculated
      const debtorsWithPercentCurrent = await this.calculatePercentCurrent(debtorsWithTotalVolume)
      // Fourth, get the average days to pay for each debtor
      const debtorsWithDetails = await this.getDetailedDebtors(debtorsWithPercentCurrent)

      this.top10Debtors = debtorsWithDetails
      this.requestsFinished = true
    },

    async getApprovedInvoices () {
      // GET all client's current approved invoices amounts and dates approved
      try {
        const invoices = (await Invoice.queryList({
          gt___approved_date: moment().startOf('day').subtract(365, 'days').toDate(),
          client_id: this.client.id,
          limit: 1000,
          offset: 0,
          sort_by: 'debtor_approved_total_amount',
          sort_direction: 'DESC',
          status: 'approved',
        }, 1000)).data.rows

        const transformedInvoices = invoices.map(invoice => transformRawInvoice(invoice))

        // Begin constructing the top 10
        this.categorizeApprovedInvoicesByDebtor(transformedInvoices)
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data Debtors "Get Approved Invoices"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting approved invoices' })
      }
    },

    async getDetailedDebtors (debtors) {
      const debtorsWithDetails = debtors

      await debtorsWithDetails.forEach(async debtor => {
        try {
          debtor.debtor = (await Debtor.get(debtor.debtor.id)).data
        }
        catch (error) {
          this.captureSentryEvent(
            'Client Data Debtors "Get Detailed Debtors"',
            {
              config: error.config,
              data: this.$data,
              details: error,
              debtors,
              debtorOnFail: debtor,
              props: this.$props,
              response: error.response,
            }
          )
        }
      })

      return debtorsWithDetails
    },

    async getPaidInvoices (debtorId) {
      try {
        // Get client's paid invoices
        const invoices = (await Invoice.queryList({
          client_id: this.client.id,
          debtor_id: debtorId,
          status: 'paid',
        }, 1000)).data.rows

        return invoices.map(invoice => transformRawInvoice(invoice))
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Data Debtors "Get Paid Invoices"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            debtorId,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting paid invoices' })
      }
    },
  },
}
</script>

<style lang="sass">
.ClientDataDebtors
</style>